// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-products-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/templates/Products/index.js" /* webpackChunkName: "component---src-templates-products-index-js" */),
  "component---src-templates-about-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-contact-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-plain-page-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/templates/plain-page/index.js" /* webpackChunkName: "component---src-templates-plain-page-index-js" */),
  "component---src-pages-index-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/mikkelwestermann/Desktop/WebDev/WebProjects/TonisGardiner/tonis-gardiner/.cache/data.json")

